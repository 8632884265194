import { cva } from 'class-variance-authority';

export const heading = cva('', {
  variants: {
    variant: {
      h1: 'text-h1',
      h2: 'text-h2',
      h3: 'text-h3',
    },
  },
  defaultVariants: {
    variant: 'h2',
  },
});
