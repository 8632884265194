import { VariantProps } from 'class-variance-authority';
import { Heading, HeadingProps } from 'react-aria-components';
import { heading } from '../../electrons/heading';

export function _Heading({
  children,
  variant,
  className,
  level = 2,
  ...props
}: HeadingProps & VariantProps<typeof heading>) {
  return (
    <Heading className={heading({ variant, className })} dir="auto" level={level} {...props}>
      {children}
    </Heading>
  );
}

export { _Heading as Heading };
